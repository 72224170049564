import { getPLPRecommendationBlocks } from '@/services/Listrak/fetchListrakRecommendationProducts'
import { error } from '@/services/Log'
import { LoadedRecommendationBlock } from '@/types/RecommendationBlocks'
import { useEffect, useState } from 'react'

export const usePLPRecommendations = () => {
  const [recommendations, setRecommendations] = useState<LoadedRecommendationBlock[]>([])
  useEffect(() => {
    const fetchRecommendations = async () => {
      setRecommendations(
        await getPLPRecommendationBlocks(),
      )
    }
    fetchRecommendations()
      .catch((e) => {
        error('Failed to fetch PLP recommendations', e)
        setRecommendations([])
      })
  }, [])
  return recommendations
}
