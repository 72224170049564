import type { RecommendationBlock } from '@/types/RecommendationBlocks'

export const PDP_LISTRAK_RECOMMENDATIONS: Array<RecommendationBlock> = [
  {
    mechandiseBlockId: 'ec6a7f40-48b7-43c2-a749-0f4094463bdc',
    carrouselHeader: 'Shop Similar Items',
    name: 'shopSimilarItems',
  },
  {
    mechandiseBlockId: 'd6be0b26-3a3f-49a8-af8b-3cac176bf4a3',
    carrouselHeader: 'Frequently Bought Together',
    name: 'frequentlyBoughtTogether',
  },
]
