import { error, log } from '@/services/Log'
import { type MinPLPProductInfo } from '@/types/PLPProducts'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import { PDP_LISTRAK_RECOMMENDATIONS } from '@/data/constants/PDP_LISTRAK_RECOMMENDATIONS'
import type {
  RecommendationBlock,
  LoadedRecommendationBlock,
} from '@/types/RecommendationBlocks'

import { thenCaught } from '@/helpers/FP'
import { SHOP_ORIGIN } from '@/services/Configuration'
import { isNotNull } from '@/helpers/isNotNull'
import { ClientSideCache } from '@/services/ClientSideCache'
import { PLP_LISTRAK_RECOMMENDATIONS } from '@/data/constants/PLP_LISTRAK_RECOMMENDATIONS'

const CacheBySku = ClientSideCache('services/Listrak/fetchListrakRecommendationProducts.ts:CacheBySku')((sku: string) => sku)

const getServerSideHeaders = (xformHeaders: Record<string, unknown>) => ({
  'Content-Type': 'application/x-www-form-urlencoded',
  origin: SHOP_ORIGIN,
  ...xformHeaders,
})

const isClientSideFormData = (xFormData: unknown): xFormData is FormData => (
  typeof window !== 'undefined'
  && typeof xFormData === 'object'
  && xFormData instanceof FormData
)

const getFormDataBody = (
  // We are using an older axios version that does not support FormData serialization server-side
  // so we can add this small module rather than using the bgger newer axios module
  FormDataModule: Awaited<typeof import('form-data')>,
) => (sku: string) => {
  const xFormData = (
    typeof window !== 'undefined'
      ? new FormData()
      : new FormDataModule()
  )
  xFormData.append('activity.sku', sku)
  xFormData.append('field', 'Sku')
  xFormData.append('field', 'Title')

  const isClient = isClientSideFormData(xFormData)
  const headersHolder = (
    isClient
      ? undefined
      : { headers: getServerSideHeaders(xFormData.getHeaders()) }
  )
  return {
    body: xFormData,
    headersHolder,
  }
}

const getListrakProductsHappyPath = async ({
  mechandiseBlockId,
  currentSKU,
  name,
}: RecommendationBlock & {
  currentSKU: string,
}) => {
  const [
    { default: Axios },
    { hitsToProducts },
    { default: FormData },
  ] = await allPromisesWithRetries(() => [
    import('axios'),
    import('@/services/Search/hitsToProducts'),
    import('form-data'),
  ])

  const { body, headersHolder } = getFormDataBody(FormData)(currentSKU.replace(/\D/g, ''))
  // // eslint-disable-next-line no-underscore-dangle
  // const sessionId = window._ltk?.Session.GlobalID
  // if (!sessionId) throw new Error('Listrak sessionId not found')
  log(`Fetching Listrak recommendation data for ${name}`)

  const sessionIdParam = (
    // eslint-disable-next-line no-underscore-dangle
    typeof window !== 'undefined' && window._ltk?.Session.GlobalID
      // eslint-disable-next-line no-underscore-dangle
      ? `?globalSessionUID=${window._ltk.Session.GlobalID}`
      : ''
  )
  const { data } = await Axios.post<unknown[]>(`https://recs.listrakbi.com/json/${mechandiseBlockId}${sessionIdParam}`, body, headersHolder)
  const nonNullRecommendations = data?.filter(isNotNull)
  log(`GOT Listrak recommendation data for ${name}: ${nonNullRecommendations?.length}`)
  const products = await hitsToProducts(nonNullRecommendations)
  log(`GOT Listrak recommendation products for ${name}: ${products?.length}`)
  return products
}

const getListrakProductsSadPath = (err: unknown): readonly MinPLPProductInfo[] => {
  error(`Listrak recommendation error: ${String(err)}`, err)
  return []
}

export const getListrakProducts = thenCaught(getListrakProductsHappyPath)(getListrakProductsSadPath)

const blockToLoadedBlock = (
  sku: string,
) => async (block: RecommendationBlock): Promise<LoadedRecommendationBlock> => {
  const products = await getListrakProducts({ ...block, currentSKU: sku })
  return { ...block, products }
}

const uncachedGetPDPRecommendationBlocks = (sku: string): Promise<LoadedRecommendationBlock[]> => (
  Promise.all(PDP_LISTRAK_RECOMMENDATIONS.map(blockToLoadedBlock(sku)))
)

export const getPDPRecommendationBlocks = CacheBySku(uncachedGetPDPRecommendationBlocks)

export const uncachedGetRecommendationBlocks = (
  blocks: RecommendationBlock[],
): Promise<LoadedRecommendationBlock[]> => (
  Promise.all(blocks.map(blockToLoadedBlock('')))
)

export const getPLPRecommendationBlocks = () => uncachedGetRecommendationBlocks(
  PLP_LISTRAK_RECOMMENDATIONS,
)
