import React, { useEffect, useMemo, useState } from 'react'

import { usePLPActions } from '@/hooks/usePLPActions'
import usePLPWatchdog from '@/hooks/usePLPWatchdog'

import type {
  BreadcrumbsType, FacetsType,
} from '@/types/ElasticSearch/SearchAPIResponse'
import type { CategoryPageBannerContentfulResponse, QueryParamsType } from '@/types'
import type { MinPLPProductInfo } from '@/types/PLPProducts'

import Head from 'next/head'
import dynamic from 'next/dynamic'
import { error, log } from '@/services/Log'
import { sendPLPViewEventConnexity } from '@/helpers/ConnexityEvents'
import { SortOptions } from '@/components/Plp/SortOptions'
import { bloomreachCategoryPagePixel, sendDataToBloomreach } from '@/components/ScriptIntegrations/BloomreachEvents'
import { handleTildedField } from '@/helpers/handleTildedField'
import { useGetExperiment } from '@/helpers/getVWOExperiment'
import { usePLPRecommendations } from '@/hooks/usePLPRecommendations'
import type { LongformProseContent } from '@/microFrontends/seoFooter/types'
import type { GraphQlCustomer } from '@/helpers/graphql'
import { usePageView } from '@/hooks/DmpAnalytics/usePageView'

const MaybeListrakRec = dynamic(import('@/components/Pdp/MaybeListrakRec'))
const Row = dynamic(import('@csc/dls/Row'))
const Col = dynamic(import('@csc/dls/Col'))
const CategoryTitle = dynamic(import('@csc/dls/CategoryTitle'))

const Pagination = dynamic(import('@csc/dls/Pagination'))
const Filter = dynamic(import('@csc/dls/Filter'))
const MobileFilterFacets = dynamic(import('./MobileFilterFacets'))

const AjaxSpinner = dynamic(import('@/components/Generic/AjaxSpinner'))
const CategoryBreadcrumb = dynamic(import('./CategoryBreadcrumbs'))
const FilterBar = dynamic(import('./FilterBar'))
const AppliedFilterBadges = dynamic(import('./AppliedFilterBadges'))
const PLPProducts = dynamic(import('./PLPProducts'))
const Banner = dynamic(import('./Banner'))
const PLPSEOBottom = dynamic(import('./PLPSEOBottom'))
const BrowsePageSeoFooter = dynamic(import('@/microFrontends/seoFooter/component').then((mod) => mod.BrowsePageSeoFooter))
interface PLPProps {
  showBreadcrumbs?: boolean,
  breadcrumbs?: BreadcrumbsType[],
  PLPHeader: string,
  totalNumberOfProducts?: number
  facets?: FacetsType[],
  queryParam: QueryParamsType,
  products?: readonly MinPLPProductInfo[],
  currentPage: number,
  baseHref: string,
  baseUrl: string,
  itemsPerPage?: number,
  plpBanner?: CategoryPageBannerContentfulResponse | null,
  userAgent?: string,
  categoryDescription?: string,
  isCategoryEvent?: boolean
  isLoading: boolean
  setIsLoading: (a: boolean) => void
  sortValue?: string
  longformProse?: LongformProseContent | null | undefined
  user: GraphQlCustomer | null,
  reloadCustomer: () => (void | Promise<void>),
  shouldTrackOnBloomreach?: boolean
}

const detectMobileHandset = (userAgent: string) => Boolean(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i.exec(userAgent))

const hasFacetName = (facet: { value: number | string | undefined }) => {
  const isString = typeof facet.value === 'string'
  const shouldShow = (isString && typeof facet.value === 'string') ? handleTildedField(facet.value) !== '' : true
  return (
    shouldShow
  )
}
type MinAvailableProps = { count: number, value: number | string | undefined }
const sortAvailable = <T extends {
  machineName: string, available: readonly MinAvailableProps[]
}>(entry: T): T => (
    entry.machineName === 'price_range'
      ? {
        ...entry,
        available: [...entry.available].filter(hasFacetName),
      } : {
        ...entry,
        available: [...entry.available].filter(hasFacetName),
      }
  )

const useBloomReachTrackingONPLP = ({
  products,
  breadcrumbs,
  queryParam,
  isCategoryEvent,
  shouldTrackOnBloomreach,
}: {
  products: readonly MinPLPProductInfo[],
  breadcrumbs: BreadcrumbsType[],
  queryParam: QueryParamsType,
  isCategoryEvent: boolean,
  shouldTrackOnBloomreach: boolean
}) => {
  usePageView({
    context: [{
      key: 'products',
      value: {
        stringValue: JSON.stringify(products.map((p) => p.sku)),
        numberValue: products.length,
        booleanValue: null,
      },
    }, {
      key: 'breadcrumbs',
      value: {
        stringValue: JSON.stringify(breadcrumbs),
        numberValue: null,
        booleanValue: null,
      },
    }],
  })
  const [sentBreadCrumbs, setSentBreadCrumbs] = useState(false)
  useEffect(() => { setSentBreadCrumbs(false) }, [breadcrumbs])
  useEffect(() => {
    try {
      if (
        shouldTrackOnBloomreach
        && isCategoryEvent
        && !sentBreadCrumbs
        && isCategoryEvent
        && products?.length
      ) {
        bloomreachCategoryPagePixel(breadcrumbs)
      } else if (shouldTrackOnBloomreach) {
        sendDataToBloomreach('SearchPage', queryParam.query).catch(error)
      }
    } catch (e) {
      log(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, products?.length])
}

const PLP: React.FC<PLPProps> = ({
  showBreadcrumbs = false,
  breadcrumbs = [],
  PLPHeader = '',
  totalNumberOfProducts = 0,
  facets = [],
  queryParam,
  products = [],
  currentPage,
  baseHref,
  baseUrl,
  itemsPerPage = 60,
  plpBanner,
  userAgent,
  categoryDescription,
  isCategoryEvent,
  isLoading,
  setIsLoading,
  longformProse,
  sortValue = '',
  user,
  reloadCustomer,
  shouldTrackOnBloomreach = true,
}) => {
  const {
    onSortChange,
    onFilterSelected,
    onClearAllFilters,
    onPageChange,
    setItemsPerPage,
  } = usePLPActions({
    queryParam,
    baseHref,
    baseUrl,
    itemsPerPage,
    setIsLoading,
    isLoading,
  })

  usePLPWatchdog({
    numberOfProducts: products?.length,
    baseHref,
    baseUrl,
  })
  const [isMobileDevice, setIsMobileDevice] = useState(userAgent && detectMobileHandset(userAgent))
  const [desktopFilterOpen, setDesktopFilterOpen] = useState<boolean>(true)
  const [mobileFilterOpen, setMobileFilterOpen] = useState<boolean>(false)

  const isFreeRugDoorstepShippingOn = useGetExperiment({
    experimentKey: 'freeRugDoorstepShipping',
    featureToggle: 'freeRugDoorstepShipping',
    forceOnFeatureToggle: 'forceFreeDSShippingABTest',
  })
  useEffect(() => {
    try {
      sendPLPViewEventConnexity(products)
    } catch (e) { log(e) }
    setIsMobileDevice(detectMobileHandset(navigator.userAgent))
  }, [products, breadcrumbs])

  useBloomReachTrackingONPLP({
    products,
    breadcrumbs,
    queryParam,
    isCategoryEvent: !!isCategoryEvent,
    shouldTrackOnBloomreach,
  })

  const plpRecommentdationBlocks = usePLPRecommendations()
  const ProductsCols = desktopFilterOpen ? 3 : 4
  const toggleFilterOpen = () => {
    setMobileFilterOpen((f) => !f)
    setDesktopFilterOpen((f) => !f)
  }
  const closeFilters = () => {
    setDesktopFilterOpen(false)
    setMobileFilterOpen(false)
  }
  const facetsWithoutReadyToShip = useMemo<FacetsType[]>(() => (
    [...facets].filter((f) => f.machineName !== 'readyToShip').map(sortAvailable)
  ), [facets])

  const imagesToPreLoad = useMemo(() => [...new Set(products.map(({ variants }) => variants?.[0].images[0]?.url_square || ''))].filter((v) => !!v).slice(0, 8), [products])
  return (
    <>
      <Head>
        {imagesToPreLoad.map((imgUrl) => (
          <link key={imgUrl} rel="preload" as="image" href={imgUrl} />
        ))}
      </Head>
      <Row cols={1} gapY="xl" className="mt-8">
        <Col>
          {showBreadcrumbs && breadcrumbs && (
            <CategoryBreadcrumb
              breadcrumbs={breadcrumbs}
            />
          )}
        </Col>
        {!plpBanner && (
          <Col>
            <CategoryTitle textTag="h1" className="text-saturatedGreen">
              {PLPHeader}
            </CategoryTitle>
          </Col>
        )}
        <Col>
          {!!plpBanner && (
            <>
              <Banner plpBanner={plpBanner} />
              <CategoryTitle textTag="h1" className="hidden">
                {PLPHeader}
              </CategoryTitle>
            </>
          )}
          <FilterBar
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            onFilterSelected={onFilterSelected}
            filterOpen={desktopFilterOpen}
            toggleFilterOpen={toggleFilterOpen}
            totalNumberOfProducts={totalNumberOfProducts}
            facets={facets}
          />
        </Col>
        <Row cols={4} gapX={{ xs: 'md', md: 'xl' }}>
          {desktopFilterOpen && (
            <Col className="hidden md:block relative md:sticky top-24 h-fit desktopFilters">
              <Row cols={1} className="h-screen-80  overflow-auto">
                <Col span={1} className={isMobileDevice ? 'w-33/34' : ''}>
                  <Filter
                    id="desktop-filters"
                    label="Filter By"
                    name="filter"
                    facets={facetsWithoutReadyToShip}
                    filterLabel="Filter By"
                    onFilterChange={onFilterSelected}
                    sortLabel="Sort by"
                    sortOptions={SortOptions}
                    sortValue={sortValue}
                    onSortChange={onSortChange}
                    data-testid="desktop-filters"
                    displayCount
                  />
                </Col>
              </Row>
            </Col>
          )}
          <Col span={{ sm: 4, md: ProductsCols }}>
            <Row cols={1}>
              <AppliedFilterBadges
                onClearFilter={onFilterSelected}
                facets={facets}
                onClearAllFilters={onClearAllFilters}
              />
              <PLPProducts
                user={user}
                reloadCustomer={reloadCustomer}
                isFreeRugDoorstepShippingOn={isFreeRugDoorstepShippingOn}
                products={products}
                cols={ProductsCols}
              />
            </Row>
          </Col>
        </Row>
        <Col
          // Add 56px padding for categoryDescription
          className={`${categoryDescription ? 'py-14' : ''}`}
        >
          <Pagination
            onPageChange={onPageChange}
            currentPage={currentPage}
            totalRecords={totalNumberOfProducts}
            recordsPerPage={itemsPerPage}
          />
        </Col>
      </Row>
      {plpRecommentdationBlocks && (
        <Col
          span={12}
          className={`${categoryDescription ? 'py-14' : ''}`}
        >

          <MaybeListrakRec
            isFreeRugDoorstepShippingOn={isFreeRugDoorstepShippingOn}
            recommendationBlocks={plpRecommentdationBlocks}
          />
        </Col>
      )}
      {categoryDescription && (
        // Add 24px padding for categoryDescription
        <Row cols={1} className="grid  border-t border-b items-center">
          <PLPSEOBottom content={categoryDescription} dataTestId="PLPSEOBottom" className="py-6" />
        </Row>
      )}
      {longformProse && (
        <BrowsePageSeoFooter longformProse={longformProse} />
      )}
      <AjaxSpinner visible={isLoading} className="z-100" />
      <MobileFilterFacets
        onSortChange={onSortChange}
        open={mobileFilterOpen}
        onClose={closeFilters}
        isLoading={isLoading}
        facets={facets}
        sortValue={sortValue}
        onClearFilters={onClearAllFilters}
        onFilterSelected={onFilterSelected}
      />
    </>
  )
}

export default PLP
