import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { log, warn } from '@/services/Log'

interface PLPWatchDogProps {
  numberOfProducts?: number,
  baseHref: string,
  baseUrl: string,
}

export const PLPWatchDog: (p: PLPWatchDogProps) => void = ({
  numberOfProducts = 0,
  baseHref,
  baseUrl,
}) => {
  const router = useRouter()
  useEffect(() => {
    if (typeof window !== 'object') {
      return
    }
    const pagDefaultFilters = baseUrl.split('?')[1].split('&').map((param) => param.split('=')[0])
    const defaultFiltersNames = [...pagDefaultFilters, 'sort']
    const filterIsNotDefault = (f: string) => !!f && !(defaultFiltersNames.includes(f))
    const filtersFromSearch = `${window.location.search}`.replace('?', '').split('&').filter((queryParam) => (
      !queryParam.startsWith('query')
    ))
    const hasFiltersApplied = !!filtersFromSearch.filter(filterIsNotDefault).length
    if (hasFiltersApplied && !numberOfProducts) {
      const href = baseHref.replace(/(\?*)(&*)$/, '')
      const url = baseUrl.replace(/(\?*)(&*)$/, '')
      warn('PLP Watchdog redirecting due to filters combination without products')
      log(`router.push( "${href}", "${url}")`)
      if (router.asPath !== href) {
        router.push(href, url)
      }
    }
  }, [numberOfProducts, baseHref, baseUrl, router])
}

export default PLPWatchDog
