import { caught } from '@/helpers/FP'
import { jsonObjectToHash } from '@/helpers/jsonObjectToHash'
import {
  assertion,
  CheckerReturnType,
  writableDict,
  mixed,
  number,
  object,
} from '@recoiljs/refine'
import { log } from '@/services/Log'

const MAX_FIFO_QUEUE_SIZE = 50

const Cache = writableDict(writableDict(mixed()))
type CacheType = CheckerReturnType<typeof Cache>
type CacheSpaceType = CacheType[keyof CacheType]
const CacheLocalStorage = object({
  lastInteractionUnixTimeStamp: number(),
  cache: Cache,
})
type CacheLocalStorageType = CheckerReturnType<typeof CacheLocalStorage>

const maxDelayToDiscardCache = 1000 * 60 * 60 // 60 minutes
const assertCacheIsFreshEnough = (cache: CacheLocalStorageType): CacheType => {
  const now = Date.now()
  const timeElapsed = now - cache.lastInteractionUnixTimeStamp
  if (timeElapsed > maxDelayToDiscardCache) {
    throw new Error('Cache is too old')
  }
  return cache.cache
}

const asseertCacheLocalStorage = assertion(CacheLocalStorage, 'Failed to assert CacheLocalStorage')
const loadCacheFromLocalStorage = caught((): CacheType => {
  if (typeof localStorage === 'undefined') {
    return {}
  }
  const localStorageCache = asseertCacheLocalStorage(JSON.parse(localStorage.getItem('ClientSideGlobalFifoCacheStorage') || ''))
  return assertCacheIsFreshEnough(localStorageCache)
})((err): CacheType => {
  console.error('Failed to load ClientSideGlobalFifoCacheStorage from localStorage', err)
  return {}
})

const saveCacheToLocalStorage = caught((cache: CacheType) => {
  if (typeof localStorage === 'undefined') {
    return
  }
  localStorage.setItem('ClientSideGlobalFifoCacheStorage', JSON.stringify({ cache, lastInteractionUnixTimeStamp: Date.now() }))
})((err) => {
  console.error('Failed to save ClientSideGlobalFifoCacheStorage to localStorage', err)
})

const ClientSideGlobalFifoCacheStorage: CacheType = loadCacheFromLocalStorage(undefined)
const FifoStorage: Array<{ space: string, key: string | number }> = []
export type Appender = <T>(p: { key: string | number, space: string, value: T }) => void
const AppendGlobalCache: Appender = ({ key, space, value }) => {
  ClientSideGlobalFifoCacheStorage[space] = ClientSideGlobalFifoCacheStorage[space] || {}
  ClientSideGlobalFifoCacheStorage[space][key] = value
  FifoStorage.push({ space, key })
  if (FifoStorage.length > MAX_FIFO_QUEUE_SIZE) {
    const removable = FifoStorage.shift()
    if (removable && !(removable.space === space && removable.key === key)) {
      delete ClientSideGlobalFifoCacheStorage[removable.space][removable.key]
    }
  }
  saveCacheToLocalStorage(ClientSideGlobalFifoCacheStorage)
}

const GlobalCacheContains = ({ key, space }: {
  key: string | number,
  space: string
}) => (
  typeof ClientSideGlobalFifoCacheStorage[space] !== 'undefined'
  && typeof ClientSideGlobalFifoCacheStorage[space][key] !== 'undefined'
)

const GlobalCacheAccess = <T>({ key, space }: {
  space: keyof CacheType
  key: keyof CacheSpaceType
}) => ClientSideGlobalFifoCacheStorage[space][key] as T
const allSpaces: string[] = []

type MaybeWithCacheControls<J, K> = J & {
  clearCache?: (key: string) => void
  setCache?: (key: string, value: K) => void
}

export const ClientSideCacheWithGeneric = (spaceTrack: string) => <T>(
  computation: (args: T) => number | string,
) => <K, J extends (args: T) => (K | Promise<K>)>(functor: J): MaybeWithCacheControls<J, K> => {
  if (!process.browser) {
    return functor
  }
  const space = jsonObjectToHash({
    spaceTrack,
  })
  allSpaces.push(space)
  log('ClientSideCacheWithGeneric', { spaceTrack, space, allSpaces })
  const returnFunctor: MaybeWithCacheControls<J, K> = ((args) => {
    const key = computation(args)
    const cacheHit = GlobalCacheContains({ space, key })
    const valuePromise = (
      cacheHit
        ? GlobalCacheAccess<K>({ space, key })
        : functor(args)
    )
    if (valuePromise instanceof Promise) {
      valuePromise.then((value) => AppendGlobalCache({ space, key, value }))
    } else {
      AppendGlobalCache({ space, key, value: valuePromise })
    }
    return valuePromise
  }) as MaybeWithCacheControls<J, K>

  returnFunctor.clearCache = (key:string) => {
    delete ClientSideGlobalFifoCacheStorage[space][key]
    saveCacheToLocalStorage(ClientSideGlobalFifoCacheStorage)
  }
  returnFunctor.setCache = (key:string, value:K) => {
    AppendGlobalCache({ space, key, value })
  }
  return returnFunctor
}

export const ClientSideCache = ClientSideCacheWithGeneric
