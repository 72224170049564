import type { SortOptionsType } from './types'

export const SortOptions: SortOptionsType = [
  {
    value: '',
    text: 'Recommended',
  },
  {
    value: 'bestSelling',
    text: 'Best Selling',
  },
  {
    value: 'newest',
    text: 'New arrivals',
  },
  {
    value: 'priceasc',
    text: 'Price: Low to High',
  },
  {
    value: 'pricedesc',
    text: 'Price: High to Low',
  },
]

export default SortOptions
