import { error } from '@/services/Log'
import type { Product } from '@/types/SearchAPI/SAPIProduct'

export const purchaseConnexityEvent = (cartTotal, orderId, lineItems, userType) => {
  if (typeof window !== 'undefined' && typeof window?.cnxtag === 'function') {
    try {
      window.cnxtag('event', 'conversion', {
        'mid': '253757',
        'cust_type': userType === 'registered' ? '0' : '1',
        'order_value': cartTotal,
        'currency': 'USD',
        'order_id': orderId,
        'units_ordered': lineItems?.length,
      })
    } catch (promoBannerError) {
      error(`
              WARNING - CONN ERROR:
              ${String(promoBannerError)}
              ${window.cnxtag.toString()}
              `)
    }
  }
}

export const viewProductConnexityEvent = (product : Product | undefined) => {
  if (typeof window !== 'undefined' && typeof window?.cnxtag === 'function') {
    try {
      window.cnxtag('event', 'pageview', { 'product_ids': [product?.sku.substring(1)] })
    } catch (promoBannerError) {
      error(`
            WARNING - CONN ERROR:
            ${String(promoBannerError)}
            ${window.cnxtag.toString()}
            `)
    }
  }
}

export const addToCartConnexityEvent = (product : Product) => {
  if (typeof window !== 'undefined' && typeof window?.cnxtag === 'function') {
    try {
      window.cnxtag('event', 'addtocart', { 'product_ids': [product.sku.substring(1)] })
    } catch (promoBannerError) {
      error(`
          WARNING - CONN ERROR:
          ${String(promoBannerError)}
          ${window.cnxtag.toString()}
          `)
    }
  }
}

type PLPViewEventConnexityProductInfo = Pick<Product, 'sku' | 'category_info'>
export const sendPLPViewEventConnexity = (
  product : readonly PLPViewEventConnexityProductInfo[],
) => {
  const categoryName = [...product.slice(0, 10)].map((i) => `${i.category_info?.[0]?.name}`)
  const categoryId = [...product.slice(0, 10)].map((i) => `${i.category_info?.[0]?.id}`)
  if (typeof window !== 'undefined' && typeof window?.cnxtag === 'function') {
    try {
      window.cnxtag('event', 'pageview', {
        'product_ids': [...product.slice(0, 10)].map((i) => `${i.sku.substring(1)}`),
        'category_id': categoryId.length ? categoryId[0] : '0',
        'category_name': categoryName.length ? categoryName[0] : 'default',
      })
    } catch (promoBannerError) {
      error(`
          WARNING - CONN ERROR:
          ${String(promoBannerError)}
          ${window.cnxtag.toString()}
          `)
    }
  }
}
