import type { RecommendationBlock } from '@/types/RecommendationBlocks'

export const PLP_LISTRAK_RECOMMENDATIONS: Array<RecommendationBlock> = [
  {
    mechandiseBlockId: '89aa3581-391b-4494-a62a-df88cba363ab',
    carrouselHeader: 'Recently Viewed',
    name: 'recentlyViewed',
  },
  {
    mechandiseBlockId: 'a9402b60-f3c8-49f7-8212-b369fa9cfb6d',
    carrouselHeader: 'Trending Products',
    name: 'trendingProducts',
  },
]
