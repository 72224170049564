import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { error, log } from '@/services/Log'
import type { QueryParamsType } from '@/types'
import type { onFilterChangeHandler, onPaginationHandler, SortValueValueType } from '@/components/Plp/types'
import { checkIfServerside } from '../helpers/checkRuntime'

const DEFAULT_SORT = ''
const acceptedFacets = [
  'condition',
  'brand',
  'color',
  'sizes',
  'price_range',
  'readyToShip',
  'category',
  'sale',
]

const SORT = 'sort'
const LIMIT = 'limit'
const PAGE = 'page'

const parseActionUrl = (action: string) => {
  const query = new Map()
  action.split('&').forEach((keyval) => {
    const [key, val] = keyval.split('=')
    query.set(key, val)
  })
  return query
}

interface PLPActionsProps {
  queryParam: QueryParamsType,
  baseHref: string,
  baseUrl: string,
  itemsPerPage: number
  setIsLoading: (a: boolean) => void
  isLoading: boolean
}

export const usePLPActions: (p: PLPActionsProps) => {
  onSortChange: (value: SortValueValueType) => void
  onFilterSelected: onFilterChangeHandler,
  onClearAllFilters: () => void
  onPageChange: onPaginationHandler
  setItemsPerPage: (items: number) => Promise<void>
} = ({
  queryParam,
  setIsLoading,
  isLoading,
}) => {
  const router = useRouter()
  const constructHrefUrl = useCallback((url: URL) => {
    const urlString = url.pathname + url.search
    const hrefQuery = router.query
    Object.entries(hrefQuery).forEach(([key, val]) => {
      if (!url.searchParams.has(key)) {
        if (val !== undefined && typeof val === 'string') {
          if (![...acceptedFacets, SORT, PAGE, LIMIT].includes(key)) {
            url.searchParams.set(key, val)
          }
        }
      }
    })
    const hrefString = router.pathname + url.search
    return {
      href: hrefString,
      url: urlString,
    }
  }, [router])
  const onSortChange = useCallback(async (value: SortValueValueType) => {
    if (checkIfServerside()) {
      return
    }
    log('onSortChange Start', { value })
    if (isLoading) {
      error('Method Called while loading')
      return
    }
    setIsLoading(true)
    try {
      const newUrl = new URL(window.location.href)
      log(`Handle Sort Change ${value}`)
      if (queryParam.sort === value) {
        return
      }
      if (value === DEFAULT_SORT) {
        newUrl.searchParams.delete(SORT)
      } else {
        newUrl.searchParams.set(SORT, value)
      }
      const { url, href } = constructHrefUrl(newUrl)
      log(`router.push( "${href}", "${url}" )`)
      await router.push(href, url)
    } catch (err) {
      error('SortChange error', err)
    } finally {
      setIsLoading(false)
    }
    log('onSortChange End')
  }, [isLoading, queryParam.sort, router, setIsLoading, constructHrefUrl])

  const onFilterSelected: onFilterChangeHandler = useCallback(async (facet, option) => {
    if (checkIfServerside()) {
      return
    }
    log('onFilterSelected Start', { facet, option })
    if (isLoading) {
      error('Method Called while loading')
      return
    }
    setIsLoading(true)
    try {
      const newUrl = new URL(window.location.href)
      const query = parseActionUrl(option.action)
      acceptedFacets.forEach((facetKey) => {
        if (newUrl.searchParams.has(facetKey) && !query.has(facetKey)) {
          // This facet is not in action url, but present in current url
          newUrl.searchParams.delete(facetKey)
        } else if (query.has(facetKey)) {
          // This facet is in action url, set it to url or replace with new val
          const value = query.get(facetKey) as string | undefined
          if (value !== undefined) {
            newUrl.searchParams.set(facetKey, decodeURIComponent(value))
          }
        }
        // The facet being iterated through is not being set, ignore
      })
      if (newUrl.searchParams.has(PAGE)) {
        newUrl.searchParams.delete(PAGE)
      }
      const { url, href } = constructHrefUrl(newUrl)
      log(`router.push( "${href}", "${url}" )`)
      await router.push(href, url)
    } catch (err) {
      error('Failed to select filter', err)
    } finally {
      setIsLoading(false)
    }
    log('onFilterSelected End')
  }, [isLoading, router, setIsLoading, constructHrefUrl])

  const onClearAllFilters = useCallback(async () => {
    if (checkIfServerside()) {
      return
    }
    log('onClearAllFilters start')
    if (isLoading) {
      error('Method Called while loading')
      return
    }
    setIsLoading(true)
    try {
      const newUrl = new URL(window.location.href)
      const paramsToClear = [
        ...acceptedFacets,
        SORT,
        LIMIT,
        PAGE,
      ]
      paramsToClear.forEach((queryKey) => {
        newUrl.searchParams.delete(queryKey)
      })
      const { url, href } = constructHrefUrl(newUrl)
      log(`router.push( "${href}", "${url}" )`)
      await router.push(href, url)
    } catch (err) {
      error(`onClearAllFilters Failed to clear all filter ${String(err)}`, err)
    } finally {
      setIsLoading(false)
    }
    log('onClearAllFilters end')
  }, [isLoading, router, setIsLoading, constructHrefUrl])

  const onPageChange: onPaginationHandler = useCallback(async ({ pageSelected }) => {
    if (checkIfServerside()) {
      return
    }
    log('onPageChange start', { pageSelected }, typeof pageSelected)
    if (isLoading) {
      error('Method Called while loading')
      return
    }
    setIsLoading(true)
    try {
      const newUrl = new URL(window.location.href)
      const currentPageStr = newUrl[PAGE] as unknown
      if (currentPageStr && typeof currentPageStr === 'string') {
        const currentPage = parseInt(currentPageStr)
        if (currentPage === pageSelected) {
          return
        }
      }

      if (pageSelected === 0) {
        newUrl.searchParams.delete(PAGE)
      } else {
        newUrl.searchParams.set(PAGE, pageSelected.toString())
      }
      const { url, href } = constructHrefUrl(newUrl)
      log(`router.push( "${href}", "${url}" )`)
      await router.push(href, url)
    } catch (err) {
      error(`Failed to clear all filter ${String(err)}`)
    } finally {
      setIsLoading(false)
    }
    log('onPageChange end')
  }, [isLoading, router, setIsLoading, constructHrefUrl])

  const setItemsPerPage = useCallback(async (newLimit: number) => {
    if (checkIfServerside()) {
      return
    }
    if (isLoading) {
      error('setItemsPerPage Method Called while loading')
      return
    }
    log('setItemsPerPage start', { newLimit })
    setIsLoading(true)
    try {
      const newUrl = new URL(window.location.href)
      const currentLimitStr = newUrl[LIMIT] as unknown
      if (currentLimitStr && typeof currentLimitStr === 'string') {
        const currentLimit = parseInt(currentLimitStr)
        if (currentLimit === newLimit) {
          return
        }
      }
      if (newLimit === 60) {
        newUrl.searchParams.delete(LIMIT)
      } else {
        newUrl.searchParams.set(LIMIT, newLimit.toString())
      }
      if (newUrl.searchParams.has(PAGE)) {
        newUrl.searchParams.delete(PAGE)
      }
      const { url, href } = constructHrefUrl(newUrl)
      log(`router.push( "${href}", "${url}" )`)
      await router.push(href, url)
    } catch (err) {
      error(`Set items per page limit failed ${String(err)}`)
    } finally {
      setIsLoading(false)
    }

    log('setItemsPerPage end')
  }, [isLoading, router, setIsLoading, constructHrefUrl])

  return {
    onSortChange,
    onFilterSelected,
    onClearAllFilters,
    onPageChange,
    setItemsPerPage,
  }
}

export default usePLPActions
